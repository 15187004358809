import {
  Box,
  Button,
  Stack,
  Spacer,
  Text,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import { motion, AnimatePresence, isValidMotionProp } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { chakra } from '@chakra-ui/react';
import FadeInAnimation from './UX/FadeInAnimation';
const ChakraBox1 = chakra(motion.div, {
  shouldForwardProp: isValidMotionProp,
});
export const Modal2 = ({ isToggled, setToggle, children, modalIDState }) => {
  const [stellenDetail, setStellenDetail] = useState([]);

  const fetchData1 = () => {
    axios
      .get(
        `https://kdw-partner.europersonal.com/api/public/v1/Stelle/GetStelleById?stelleUuid=${modalIDState}&cultureName=de-DE`
      )
      .then((response) => {
        console.log('DATA.FETCH.RESPONSE.COMPLETE');
        console.log(response);
        setStellenDetail(response.data);
        console.log('DATA.FETCH.DETAILS.COMPLETE');
        console.log({ stellenDetail });
        console.log('***********------*******');
      });
  };
  useEffect(() => {
    if (modalIDState != null) {
      console.log('USEFFECT');
      fetchData1();
    }
  }, [isToggled]);

  return (
    <AnimatePresence>
      {isToggled && (
        <Box
          bg='rgba(0,0,0,0.5)'
          position='fixed'
          top='1px'
          zIndex={'9999999999'}
          left='0px'
          minW='200vw'
          h='100vh'
        >
          <motion.div
            position='relative'
            top={{ base: '0px', lg: '2px' }}
            left={{ base: 0, lg: '1%' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            mt={{ base: 0, lg: '2vh' }}
          >
            <Stack
              w={{ base: '90vw', md: '50vw' }}
              mt={{ base: '2vh', lg: '5vh' }}
              as={motion.div}
              h={{ base: '90vh', md: '90vh' }}
              position='fixed'
              zIndex='99999'
              initial={{ y: 50, scale: 0.2 }}
              animate={{ y: 0, scale: 1 }}
              exit={{ y: -50, scale: 0.1 }}
              p={{ base: 8, lg: 16 }}
              top={'0'}
              left={{ base: '4%', lg: '25%' }}
              boxShadow='xl'
              overflow={'scroll'}
              bg={'#ffffff'}
              borderRadius='11px'
            >
              <FadeInAnimation
                threshold={0.4}
                delay={0.22}
                duration={1.0}
                initialX={0}
                initialY={10}
              >
                <Heading
                  fontSize={{ base: '3xl', lg: '3xl' }}
                  fontWeight='normal'
                  color='brand.violet'
                  mb='4'
                  w={'100%'}
                  letterSpacing='tighter'
                  dangerouslySetInnerHTML={{
                    __html: stellenDetail.StellenzielHeader,
                  }}
                ></Heading>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.0}
                initialX={0}
                initialY={10}
              >
                <Text
                  color='brand.gray.light'
                  fontSize={{ base: 'lg', md: 'lg' }}
                  fontWeight='normal'
                  dangerouslySetInnerHTML={{
                    __html: stellenDetail.Arbeitgeberleistung,
                  }}
                ></Text>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <Heading
                  fontSize={{ base: '2xl', lg: '3xl' }}
                  fontWeight='normal'
                  color='brand.yellow'
                  mt='4'
                  w={'100%'}
                  letterSpacing='tighter'
                >
                  Aufgaben
                </Heading>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.75}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <Text
                  color='brand.gray.light'
                  fontSize={{ base: 'md', md: 'lg' }}
                  fontWeight='normal'
                  dangerouslySetInnerHTML={{
                    __html: stellenDetail.Aufgaben,
                  }}
                ></Text>
              </FadeInAnimation>
              <Spacer />
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <Flex mt={12} justify='space-between'>
                  <Button
                    px={6}
                    textAlign='center'
                    variant={'ghost'}
                    color='brand.violet'
                    onClick={() => setToggle(false)}
                  >
                    Schliessen
                  </Button>
                  <Link href={stellenDetail.StelleUrl}>
                    <Button
                      flex={1}
                      bg='brand.violet'
                      px='2rem'
                      fontSize={'sm'}
                      rounded={'full'}
                      color={'white'}
                      boxShadow={
                        '0px 1px 25px -5px rgb(100 100 180 / 48%), 0 10px 10px -5px rgb(100 100  180  / 43%)'
                      }
                      _hover={{
                        bg: 'brand.yellow',
                      }}
                      _focus={{
                        bg: 'blue.500',
                      }}
                    >
                      zur Bewerbung
                    </Button>
                  </Link>
                </Flex>
              </FadeInAnimation>
            </Stack>
          </motion.div>
        </Box>
      )}
    </AnimatePresence>
  );
};
