import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  useColorMode,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ImgTextModule from '../components/elements/ImgTextModule';
import { Modal2 } from '../components/ModalDVT';
import Stellenanzeige from '../components/stellenanzeige';
import FadeInAnimation from '../components/UX/FadeInAnimation';
import InViewTrigger from '../components/Utility/UX/InViewTrigger';
import AnimatedCountUpDecimal from '../components/UX/AnimatedCountUpDecimal';
import { AnimatePresence, motion } from 'framer-motion';
import KontaktFooter from '../components/KontaktFooter';

const Stellen = () => {
  const { setColorMode } = useColorMode();
  const [isToggledDVT, setToggleDVT] = useState(null);
  const updateStateModal = (toggleState, StellenID) => {
    setToggleDVT(toggleState);
    setModalIDstate(StellenID);
  };

  const [modalIDState, setModalIDstate] = useState(null);

  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [filterTerm, setFilterTerm] = useState([]);

  const [filters1, setFilters1] = useState([]);
  const [stellenNummer, setStellennummer] = useState([]);

  const fetchData = (filterTerm) => {
    axios
      .get(
        'https://kdw-partner.europersonal.com/api/public/v1/Stelle/GetStellenFiltered?topjobsFirst=false&generateCoordinates=false&additionalProperties=StelleUrl&cultureName=de-DE'
      )
      .then((response) => {
        setUsers(response.data.Items);
        setFiltered(response.data.Items);
        setStellennummer(response.data.Items.length);
      });
  };

  const fetchDataFilters = () => {
    axios
      .get(
        'https://kdw-partner.europersonal.com/api/public/v1/Stelle/GetStellenFiltered?topjobsFirst=false&generateCoordinates=false&additionalProperties=EinsatzortOrt'
      )
      .then((response) => {
        // console.log("DATARESPONSEFILTERS.COMPLETE");
        // console.log(response.data.Items);
        // console.log("***********------");
        let a = response.data.Items.map((item) => item.EinsatzortOrt);
        let b = new Set(a);
        let c = Array.from(b);
        c.sort();
        console.log('DATAR reduceS.COMPLETE');
        console.log(c);
        console.log('***********------');
        setFilters1(c);
      });
  };

  useEffect(() => {
    fetchData();
    fetchDataFilters();
  }, [null]);

  useEffect(() => {
    console.log('filterterm set!');
    console.log(filterTerm);
    if (filterTerm == '') {
      setFiltered(users);
      return;
    }
    const filtered = users.filter((user) =>
      user.EinsatzortOrt.includes(filterTerm)
    );
    setFiltered(filtered);
    console.log('*************');
    console.log(filtered);
  }, [filterTerm]);

  return (
    <>
      <Helmet>
        <title>KDW | Stellenanzeigen</title>
        <meta
          name='description'
          content='kdw HR bietet Kunden Dienstleistungen von der Personalvermittlung, über qualifizierte Arbeitnehmerüberlassung bis hin zu Management- und Fördermittelberatung im gesamten Personalmanagementbereich. Arbeitnehmer finden bei uns langfristige Karriereoptionen genauso wie neue Herausforderungen vornehmlich im Industrie-, Gewerbe- und Officesegment.'
        />
        <link rel='canonical' href='https:/kdw-hr.de' />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Helmet>

      <ImgTextModule
        src={
          '439342_Chess_Chess_Pieces_Game_King_By_Storyfootage_Artlist_HD.mp4'
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h='75vh'
        mediaOpacity={0.8}
        bgColor='brand.gray.100'
      >
        <Container alignSelf={'end'} variant='layoutContainer' mt={18}>
          <Box p={{ base: 0, lg: '8' }}>
            <Flex flexDirection={'column'}>
              <Box>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.7}
                  initialX={0}
                  initialY={-10}
                ></FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.4}
                  duration={0.4}
                  initialX={0}
                  initialY={-10}
                >
                  <Text
                    color='white'
                    fontWeight='normal'
                    mx={'auto'}
                    textAlign={'center'}
                    fontSize={{ base: '2xl', lg: '3xl' }}
                  >
                    Jobs
                  </Text>
                  <Text
                    color='brand.green'
                    fontWeight='normal'
                    mx={'auto'}
                    textAlign={'center'}
                    fontSize={{ base: '3xl', lg: '6xl' }}
                  >
                    Wir sind das perfekte Match
                  </Text>
                </FadeInAnimation>
                <FadeInAnimation
                  threshold={0.4}
                  delay={1}
                  duration={0.7}
                  initialX={0}
                  initialY={10}
                >
                  <Text
                    color='brand.yellow'
                    mb={2}
                    mx={'auto'}
                    textAlign={'center'}
                    maxW={{ base: '100%', lg: '80%' }}
                    fontSize={{ base: '2xl', lg: '4xl' }}
                    lineHeight={'short'}
                  >
                    für Ihren nächsten Schritt.
                  </Text>
                </FadeInAnimation>
              </Box>
              <InViewTrigger threshold={0.1} triggerOnce={false}>
                {(inView) => (
                  <Flex
                    mx={'auto'}
                    w={'30vw'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    mt={12}
                    justifyContent={{ base: 'center', lg: 'center' }}
                  >
                    <AnimatedCountUpDecimal
                      inView={inView}
                      unit=' '
                      fontSizeUnit='lg'
                      count={stellenNummer}
                      fontSize={'6rem'}
                      duration={1}
                      decimal={false}
                      decimalDuration={2}
                      decimalCount={0}
                      unitSpacing={3}
                      numberColor={'white'}
                      textColor={'white'}
                      cellHeight={'6rem'}
                      letterSpacing={'-0.2rem'}
                    ></AnimatedCountUpDecimal>
                    <Text
                      color='brand.yellow'
                      mb={2}
                      mx={'auto'}
                      textAlign={'center'}
                      maxW={{ base: '100%', lg: '80%' }}
                      fontSize={{ base: 'md', lg: 'md' }}
                      lineHeight={'short'}
                    >
                      freie Stellen bundesweit.
                    </Text>
                  </Flex>
                )}
              </InViewTrigger>
            </Flex>
          </Box>
        </Container>
      </ImgTextModule>

      <Container variant='layoutContainer' py={24}>
        <FadeInAnimation
          threshold={0.4}
          delay={0.5}
          duration={0.7}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.green'
            textAlign={'center'}
            mb={2}
            maxW={{ base: '100%', lg: '100%' }}
            fontSize={{ base: '4xl', lg: '6xl' }}
            lineHeight={'short'}
          >
            Wir suchen aktuell:
          </Heading>
        </FadeInAnimation>
        <FadeInAnimation
          threshold={0.4}
          delay={1}
          duration={0.9}
          initialX={0}
          initialY={10}
        >
          <Heading
            color='brand.yellow'
            textAlign={'center'}
            mb={2}
            maxW={{ base: '100%', lg: '100%' }}
            fontSize={{ base: '2xl', lg: '3xl' }}
            lineHeight={'short'}
          >
            Unsere freien Stellen.
          </Heading>
        </FadeInAnimation>

        {filters1.length > 0 && (
          <Flex
            maxW={{ base: '100%', lg: '50%' }}
            w={{ base: '100%', lg: '50%' }}
            mx='auto'
            my={12}
            wrap='wrap'
            justify='center'
          >
            <Button mb={4} px={8} mr={4} onClick={() => setFilterTerm('')}>
              Alle
            </Button>
            <Select
              maxW={{ base: '100%', lg: '50%' }}
              w={{ base: '100%', lg: '50%' }}
              placeholder='Ortauswahl'
              onChange={(e) => setFilterTerm(e.target.value)}
            >
              {filters1.map((filter1) => (
                <option value={filter1}>{filter1}</option>
              ))}
            </Select>
          </Flex>
        )}

        {filtered.length > 0 && (
          <Flex wrap='wrap' justify='center' mx='auto'>
            {filtered.map((user) => (
              <AnimatePresence>
                <motion.div
                  layout
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 10 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 1,
                    delay: 0,
                  }}
                >
                  <Stellenanzeige
                    mx={4}
                    sx={{ border: '1px' }}
                    updateStateModal={updateStateModal}
                    CultureName={user.CultureName}
                    key={user.StellenID}
                    StellenID={user.StellenID}
                    Bezeichnung={user.Bezeichnung}
                    Beginn={user.Beginn}
                    EinsatzPlz={user.EinsatzPlz}
                    EinsatzOrt={user.EinsatzortOrt}
                    Vertragsarten={user.Vertragsarten}
                    DatumAb={user.DatumAb}
                    StellenUUID={user.StelleUuid}
                    LinkUrl={user.ZusaetzlicheEigenschaften.StelleUrl}
                    VertragsartenString={user.VertragsartenString}
                  />
                </motion.div>
              </AnimatePresence>
            ))}
          </Flex>
        )}
      </Container>
      <Modal2
        isToggled={isToggledDVT}
        modalIDState={modalIDState}
        setToggle={setToggleDVT}
      ></Modal2>
      <KontaktFooter />
    </>
  );
};

export default Stellen;
